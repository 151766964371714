import React from "react";

const Certifications = () => {
  return (
    <div id="aboutme" className="container ">
      <h3 className="ui horizontal header divider mt-5 mb-4">
        <p className="mb-3 mt-3">Certifications</p>
      </h3>

      <div className="row">
        <div className="col-md-6 text-center">
          <img src={require("../img/aws.png")} alt="aws" />
          <p className="mt-4 mb-4">
            <b>AWS Cloud Developer - Associate</b>
          </p>
        </div>
        <div className="col-md-6 text-center">
          <img
            src={require("../img/aws-certified-solutions-architect-associate.png")}
            alt="aws"
          />
          <p className="mt-4 mb-4">
            <b>AWS Solutions Architect - Associate</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
