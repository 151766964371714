import React from "react";

const Professional = () => {
  return (
    <div id="professional" className="container mt-3">
      <h3 className="ui horizontal header divider mt-5 mb-5">
        <p>Professional</p>
      </h3>
      <div className="ui">
        <div className="ui  grid">
          <div className="col-md-12 col-sm-12">
            <h3>
              JP Morgan Chase & Co. - <i>Software Engineer</i> on{" "}
              <i>Site Reliability Engineering Team </i>
            </h3>
            <div className="ui bulleted list">
              <div className="item">
                Creating a fleet of Java microservices to automate Control-M
                CI/CD pipeline
              </div>
              <div className="item">
                Developing XML parser microservice to enforce syntactic
                standards
              </div>
              <div className="item">
                Reduced duration of long running job from 9 hours to 3 using
                parallel processing
              </div>
              <div className="item">
                Technologies used: Java, Spring Boot, Maven, Cloud Foundry
              </div>
            </div>
            <h3>
              Medium - <i>Technical Writer/Teacher</i>
            </h3>
            <div className="ui bulleted list">
              <div className="item">
                Giving back to the developer community by teaching what I’ve
                learned
              </div>
              <div className="item">
                Consistently averaging 40,000 views over 30-day spans
              </div>
              <div className="item">
                Published by top programming publishers:{" "}
                <i>Better Programming</i>, <i>The Startup</i>, and{" "}
                <i>Towards Data Science</i>
              </div>
              <div className="item">
                Reviews of my work: “Thank you for this awesome post!” and
                “Thank you for this writeup it was super helpful”
              </div>
            </div>
            <h3>
              Economy League - Force For Good Project - <i>Web Developer</i>
            </h3>
            <div className="ui bulleted list">
              <div className="item">
                End to end development of non-profit Philadelphia Anchors of
                Growth and Equity platform
              </div>
              <div className="item">
                Designed layout of website with Balsamiq wireframes during
                weekly meetings with client
              </div>
              <div className="item">
                Implemented design with custom code in Node JS, Apostrophe
                framework, MongoDB and Bootstrap
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Professional;
